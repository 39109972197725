import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import dynamic from 'next/dynamic';
import settings from '@/settings';
import {
  BLACK_FRIDAY_START_DATE,
  BOXING_DAY_END_DATE,
  BOXING_DAY_START_DATE,
  CYBER_MONDAY_START_DATE,
  HOLIDAYS_START_DATE,
} from '@/lib/holidays/constants';
import BoxingDay from './Themes/BoxingDay';

const DefaultHero = dynamic(() => import('./Themes/DefaultHero'), {
  ssr: true,
});

const BlackFriday = dynamic(() => import('./Themes/BlackFriday'), {
  ssr: true,
});

const CyberMonday = dynamic(() => import('./Themes/CyberMonday'), {
  ssr: true,
});

const Holidays = dynamic(() => import('./Themes/Holidays'), {
  ssr: true,
});

dayjs.extend(utc);
dayjs.extend(tz);

const Main = () => {
  const { store } = settings;
  const storeTimeZone = REEBELO_TZ_NAME[store];

  // Starting From December 25, 2024 until December 26th
  if (
    dayjs.utc().tz(storeTimeZone).isSameOrAfter(BOXING_DAY_START_DATE) &&
    dayjs.utc().tz(storeTimeZone).isBefore(BOXING_DAY_END_DATE) &&
    ['reebelo-ca', 'reebelo-au'].includes(store)
  )
    return <BoxingDay />;

  // Starting From December 7, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(HOLIDAYS_START_DATE))
    return <Holidays />;

  // Starting From November 30, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(CYBER_MONDAY_START_DATE))
    return <CyberMonday />;

  // Starting From November 20, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(BLACK_FRIDAY_START_DATE))
    return <BlackFriday />;

  return <DefaultHero />;
};

export default Main;
