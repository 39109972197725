import Image from 'next/future/image';
import { handleFreeReturn } from '@lambda/commons/helpers';
import TrustpilotWidget from '../products/TrustpilotWidget';
import umbrella from '@/public/icons/collection/umbrella.svg';
import settings from '@/settings';
import certified from '@/public/icons/collection/certified.svg';
import money from '@/public/icons/collection/money.svg';
import HolidaysReturnInfo from '../commons/Holidays/ReturnInfo';

const freeReturnDays = handleFreeReturn(
  settings.store,
  settings.free_return_days,
);

const CONTENT = [
  {
    title: `${
      ['reebelo-us', 'quista'].includes(settings.store) ? 'Up to ' : ''
    }${settings.warranty_month}-Month Warranty`,
    icon: umbrella,
  },
  { title: 'Certified Sellers', icon: certified },
  { title: `${freeReturnDays} Days Free Returns`, icon: money },
];

const SubHero = () => (
  <>
    <HolidaysReturnInfo />
    <div className="border-b bg-white pb-3 pt-5 lg:py-3">
      <div className="reebelo-container flex flex-col items-center justify-between gap-5 lg:flex-row">
        <div className="flex w-full justify-between gap-3 xs:justify-around lg:justify-start lg:gap-10">
          {CONTENT.map((item) => (
            <div className="flex flex-col items-center justify-center gap-2 text-center text-xs sm:flex-row">
              <div className="h-4 w-4">
                <Image
                  src={item.icon}
                  className="h-full w-full object-contain"
                  alt={item.title}
                />
              </div>
              {item.title}
            </div>
          ))}
        </div>
        <TrustpilotWidget
          homePage={true}
          desktopAlign="right"
          mobileAlign="center"
          starClassName="h-4 w-4 xs:h-5 xs:w-5"
        />
      </div>
    </div>
  </>
);

export default SubHero;
