import { getEdgeUrl } from '@/lib/getEdgeUrl';
import { Content } from './types';

const LaptopsDesktop = getEdgeUrl('images/homepage/banner/laptops-desktop.png');
const LaptopsMobile = getEdgeUrl('images/homepage/banner/laptops-mobile.png');
const IpadsDesktop = getEdgeUrl('images/homepage/banner/ipads-desktop.png');
const IpadsMobile = getEdgeUrl('images/homepage/banner/ipads-mobile.png');
const ScootersDesktop = getEdgeUrl(
  'images/homepage/banner/e-scooters-desktop.png',
);
const ScootersMobile = getEdgeUrl(
  'images/homepage/banner/e-scooters-mobile.png',
);
const BabyDesktop = getEdgeUrl('images/homepage/banner/baby-desktop.png');
const BabyMobile = getEdgeUrl('images/homepage/banner/baby-mobile.png');

const SportFitnessDesktop = getEdgeUrl(
  'images/homepage/banner/sport-fitness.png',
);
const SportFitnessMobile = getEdgeUrl(
  'images/homepage/banner/sport-fitness-mobile.png',
);
const PowerToolsDesktop = getEdgeUrl('images/homepage/banner/power-tools.png');
const PowerToolsMobile = getEdgeUrl(
  'images/homepage/banner/power-tools-mobile.png',
);

const CONFIG: Content[] = [
  {
    title: 'Refurbished Laptops',
    description: {
      desktop:
        'Explore a wide variety of expertly refurbished laptop picks - up to 70% off.',
      mobile: 'Expertly refurbished laptop picks up to 70% off.',
    },
    cta: {
      label: 'Shop Now',
      link: 'laptops',
      tracking: 'Laptops',
    },
    banner: { desktop: LaptopsDesktop, mobile: LaptopsMobile },
    theme: 'light',
  },
  {
    title: 'Refurbished iPads',
    description: {
      desktop:
        'On the go or on the fly - refurbished iPad upgrades that can get it done.',
      mobile:
        'On the go or on the fly - refurbished iPad that can get it done.',
    },
    cta: {
      label: 'Shop Now',
      link: 'apple-ipads',
      tracking: 'iPads',
    },
    banner: { desktop: IpadsDesktop, mobile: IpadsMobile },
    theme: 'dark',
  },
  {
    title: 'Refurbished E-scooters',
    description: {
      desktop:
        'A one-of-a-kind ride, refurbished e-scooters are here. Enjoy 14-day returns and 12-month warranty plans.',
      mobile: 'A one-of-a-kind ride, refurbished e-scooters are here.',
    },
    cta: {
      label: 'Shop Now',
      link: 'e-scooters',
      tracking: 'E-scooters',
    },
    banner: { desktop: ScootersDesktop, mobile: ScootersMobile },
    theme: 'light',
  },
  {
    title: 'Certified Preloved Baby Gear',
    description: {
      desktop:
        'A sustainable start to every milestone - find quality baby gear to get you around in style & comfort. Now up to 70% off.',
      mobile:
        'A sustainable start to every milestone - find quality baby gear to get you around in style & comfort. Now up to 70% off.',
    },
    cta: {
      label: 'Shop Now',
      link: 'baby',
      tracking: 'Baby',
    },
    banner: { desktop: BabyDesktop, mobile: BabyMobile },
    theme: 'light',
  },
  {
    title: 'Refurbished & Used Power Tools',
    description: {
      desktop:
        'Empower your projects with high-quality, durable tools. Now up to 70% off.',
      mobile:
        'Empower your projects with high-quality, durable tools. Now up to 70% off.',
    },
    cta: {
      label: 'Shop Now',
      link: 'power-tools',
      tracking: 'Power Tools',
    },
    banner: { desktop: PowerToolsDesktop, mobile: PowerToolsMobile },
    theme: 'dark',
  },
  {
    title: 'Reconditioned Sports & Fitness Equipment',
    description: {
      desktop: `Score big on certified preloved sports and fitness equipment. It’s game on.`,
      mobile: `Score big on certified preloved sports and fitness equipment. It’s game on.`,
    },
    cta: {
      label: 'Shop Now',
      link: 'sports-and-fitness',
      tracking: 'Sport & Fitness',
    },
    banner: { desktop: SportFitnessDesktop, mobile: SportFitnessMobile },
    theme: 'light',
  },
];

export default CONFIG;
