import { useRouter } from 'next/router';
import cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { REEBELO_STORES, ReebeloStoreT } from '@lambda/reebelo';
import { REEBELO_COUNTRY_CODE } from '@lambda/reebelo/src/types';
import { handleFreeReturn } from '@lambda/commons/helpers';
import settings from '@/settings';
import useOriginCountry from '@/lib/use-origin-country';
import LangCountrySelecter from '@/components/commons/Footer/LangCountrySelecter';
import i18n from '@/settings/i18n';
import { WebsiteSchema } from '@/components/homepage/WebsiteSchema';
import Hero from '@/components/homepage/hero';
import { useRecentlyViewed } from '@/lib/use-recently-viewed';
import MostPopularDevices from '@/components/homepage/MostPopularDevices';
import SubHero from '@/components/homepage/SubHero';
import Section from '@/components/commons/Section';
import Vendors from '@/components/homepage/vendors/Vendors';

const t = i18n.init();

const PopularCategories = dynamic(
  () => import('@/components/homepage/PopularCategories'),
  {
    ssr: true,
  },
);

const RecentReviews = dynamic(
  () => import('@/components/homepage/RecentReviews'),
  {
    ssr: false,
  },
);

const RecentlyViewed = dynamic(
  () => import('@/components/homepage/RecentlyViewed'),
  {
    ssr: false,
  },
);

const Deals = dynamic(() => import('@/components/homepage/Deals'), {
  ssr: false,
});

const CategoryThemeSlider = dynamic(
  () => import('@/components/homepage/CategoryThemeSlider'),
  {
    ssr: false,
  },
);

const Marketplace = dynamic(() => import('@/components/commons/Marketplace'), {
  ssr: false,
});
const CircularEconomy = dynamic(
  () => import('@/components/homepage/CircularEconomy'),
  {
    ssr: false,
  },
);

const FeaturedClient = dynamic(
  () => import('@/components/homepage/FeaturedClient'),
  { ssr: false },
);

const freeReturnDays = handleFreeReturn(
  settings.store,
  settings.free_return_days,
);

export default function Home() {
  const router = useRouter();
  const originCountry = useOriginCountry();
  const [showCountryPopup, setShowCountryPopup] = useState<boolean>(false);

  useEffect(() => {
    const { asPath = '' } = router;

    if (asPath.includes('popupFor')) {
      const splittedQS = asPath.replace(/[?/]/g, '').split('&');
      const popupForQS = splittedQS.find((value) => value.includes('popupFor'));

      if (!popupForQS) return;

      const [, store] = popupForQS.split('=');

      if (REEBELO_STORES.includes(store as ReebeloStoreT)) {
        setShowCountryPopup(false);
        cookie.set(`seen-country-popup-${store}`, 'true');
      }
    }
  }, []);

  useEffect(() => {
    const COOKIE_NAME = `seen-country-popup-${settings.store}`;
    const seenCountryPopup = cookie.get(COOKIE_NAME);

    if (
      !originCountry ||
      seenCountryPopup ||
      REEBELO_COUNTRY_CODE[settings.store] === originCountry
    )
      return;

    setShowCountryPopup(true);
    // set it to 400 days since this is the maximum value for chrome
    cookie.set(COOKIE_NAME, 'true', { expires: 400 });
  }, [originCountry]);

  type LangSelectorArgs = {
    store?: string;
  };

  const handleLangSelectorClose = (data: LangSelectorArgs | undefined) => {
    if (data && data.store) {
      const COOKIE_NAME = `seen-country-popup-${data.store}`;

      // set it to 400 days since this is the maximum value for chrome
      cookie.set(COOKIE_NAME, 'true', { expires: 400 });
    }

    setShowCountryPopup(false);
  };

  const [recentlyViewed, fetchingRecentlyViewed] = useRecentlyViewed();

  return (
    <>
      <Head>
        <title>
          {settings.store === 'reebelo-us' || settings.store === 'reebelo-ca'
            ? 'Certified Refurbished Tech for Less on Reebelo'
            : t`Certified Refurbished Tech for Less on Reebelo`}
        </title>
        <WebsiteSchema />
        <meta
          name="description"
          content={t`	
          Empower yourself with your favorite tech from Reebelo. Enjoy up to 70% off high-quality iPhones, laptops, iPads and more. ${{
            free_return_days: freeReturnDays,
          }} day free returns. 1 year warranty.`}
        />
      </Head>
      {/* Home Top Banner Section */}
      <Hero />
      <SubHero />
      <div className="bg-white">
        <div className="reebelo-container">
          <RecentlyViewed
            recentlyViewed={recentlyViewed}
            fetching={fetchingRecentlyViewed}
            isHomepage
          />
        </div>
        {/* Popular Categories */}
        <PopularCategories isHomePage />
      </div>
      <div className="reebelo-container">
        <Section>
          <MostPopularDevices />
        </Section>
      </div>
      <Deals />
      <Section className="bg-teal-500 pt-8">
        <Marketplace view="default" />
      </Section>
      <div className="bg-white">
        <div className="reebelo-container">
          <Section>
            <CircularEconomy />
          </Section>
        </div>
      </div>
      <div className="reebelo-container">
        <Section>
          <RecentReviews />
        </Section>
      </div>
      <CategoryThemeSlider />
      {settings.store === 'reebelo-us' && (
        <div className="reebelo-container">
          <Section>
            <Vendors />
          </Section>
        </div>
      )}
      <LangCountrySelecter
        isOneTimePopup={true}
        isOpen={showCountryPopup}
        onClose={handleLangSelectorClose}
      />
      <div className="bg-white py-8 xxs:py-10 md:py-12 lg:py-14">
        <div className="reebelo-container">
          <FeaturedClient />
        </div>
      </div>
    </>
  );
}
