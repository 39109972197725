import Image from 'next/future/image';
import Link from 'next/link';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const BoxingDay = () => {
  const desktopLeft = getEdgeUrl(
    'images/homepage/banner/holidays/boxing-day/desktop-left.png',
  );
  const desktopRight = getEdgeUrl(
    'images/homepage/banner/holidays/boxing-day/desktop-right.png',
  );
  const mobileLeft = getEdgeUrl(
    'images/homepage/banner/holidays/boxing-day/mobile-left.png',
  );
  const mobileRight = getEdgeUrl(
    'images/homepage/banner/holidays/boxing-day/mobile-right.png',
  );

  return (
    <Link href="/collections/hot-deals" prefetch>
      <a className="relative flex h-full w-screen flex-col items-center justify-center overflow-hidden bg-[#f5ffff] text-gray-700">
        {/* Left Block  */}
        <div className="absolute hidden md:left-[-175px] md:inline-block lg:left-5 xl:left-16">
          <div className="h-[280px]">
            <Image
              src={desktopLeft}
              alt="Left Gadgets"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-left"
              quality={100}
              priority
            />
          </div>
        </div>
        {/* Right Block */}
        <div className="absolute hidden md:right-[-175px] md:inline-block lg:right-5 xl:right-16">
          <div className="h-[280px]">
            <Image
              src={desktopRight}
              alt="Right Gadgets"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-left"
              quality={100}
              priority
            />
          </div>
        </div>
        {/* Mobile Left */}
        <div className="absolute left-[-20px] xxs:left-0 md:hidden">
          <div className="h-[325px]">
            <Image
              src={mobileLeft}
              alt="Smartphone"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-left"
              priority
            />
          </div>
        </div>
        {/* Mobile Right */}
        <div className="absolute right-[-40px] top-0 xxs:right-0 md:hidden">
          <div className="h-[325px] object-cover object-right-top">
            <Image
              src={mobileRight}
              alt="Smartwatch"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-right"
              priority
            />
          </div>
        </div>
        <div className="relative">
          <div className="reebelo-container">
            <div className="flex flex-col items-center justify-center">
              <h1 className="mt-1.5 text-center text-lg font-semibold leading-tight xxs:text-xl xs:text-2xl md:text-3xl xl:text-4xl">
                Unbox the Savings
              </h1>
              <h3 className="mt-2 text-center text-sm sm:text-base">
                Up to 70% off Boxing Day Specials
              </h3>
              <div className="mt-5 flex h-10 items-center justify-center rounded-full !bg-gray-700 px-5 text-xs font-semibold text-[#F5FFFF]">
                Shop Deals
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default BoxingDay;
