import Image from 'next/future/image';
import cn from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { SHOULD_SHOW_HOLIDAY_RETURNS } from '@/lib/holidays/constants';
import returnIconBlack from '@/public/icons/return-black.svg';
import returnIconWhite from '@/public/icons/return-white.svg';
import { generateTheme } from '@/components/collections-v2/common/Collections/Banner';

dayjs.extend(utc);
dayjs.extend(tz);

const HolidaysReturnInfo = ({ className }: { className?: string }) => {
  const theme = generateTheme();

  if (SHOULD_SHOW_HOLIDAY_RETURNS) {
    return (
      <div
        className={cn(
          'flex items-center justify-center gap-x-2  py-2 px-5 text-center text-xs  sm:text-sm',
          className,
          {
            'bg-red text-white': theme === 'HOLIDAYS',
            'bg-teal-500 text-gray-700': theme !== 'HOLIDAYS',
          },
        )}
      >
        <div className="hidden h-4 w-4 shrink-0 xs:inline-block sm:h-5 sm:w-5">
          <Image
            src={theme === 'HOLIDAYS' ? returnIconWhite : returnIconBlack}
            className="h-full w-full object-contain"
            alt="Holiday Returns"
          />
        </div>
        <span>Free Holiday Returns till Jan 15, 2025</span>
      </div>
    );
  }

  return null;
};

export default HolidaysReturnInfo;
