import { ReebeloStoreT } from '@lambda/reebelo';
import USContent from './US';
import AUContent from './AU';
import CAContent from './CA';
import SGContent from './SG';
import NZContent from './NZ';
import { Content } from './types';

export const generateContent = (store: ReebeloStoreT): Content[] => {
  switch (store) {
    case 'reebelo-us':
      return USContent;
    case 'reebelo-au':
      return AUContent;
    case 'reebelo-ca':
      return CAContent;
    case 'quista':
      return SGContent;
    case 'reebelo-nz':
      return NZContent;
    default:
      return USContent;
  }
};
